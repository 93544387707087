
// Initialize Aos
AOS.init();

let notificationBell = document.getElementById("notificationBell");
let notificationContent = document.getElementById("notificationContent");

notificationBell.addEventListener("click", function() {
    notificationContent.classList.toggle("d-none");
});

